.help-centre_cta a {
  width: calc(100% - 3.5rem);
  background-color: white;
  color: #0D8426;
}

.help-centre_cta > a > svg > path {
  fill: #0D8426;
}

@media screen and (min-width: 768px) {
  .help-centre_cta a {
    width: auto;
  }
}

