.tell-us-about-the-problem_customer-input {
  margin-right: 1.5rem;
  margin-bottom: 2rem;
}

.tell-us-about-the-problem_character-counter {
  text-align: right;
  margin-top: 0.5rem;
}

.tell-us-about-the-problem_character-counter .error-red {
  color: #c7153e;
}
