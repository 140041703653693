.radio-set {
  margin-bottom: 1rem;
}

.radio-set fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

.radio-set legend {
  padding: 0;
  margin: 0;
}

.radio-set label::after {
  top: 1.6rem;
  left: 0.8rem;
}

.radio-set label span {
  font-weight: 400;
}
