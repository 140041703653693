.footer {
  text-align: center;
  font-size: 1.5rem;
}

.footer_links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer_links li {
  display: inline;
  margin-bottom: 0.5em;
}

.footer_links li::before {
  content: '|';
  margin: 0 0.5rem;
}

.footer_links li:first-child:before {
  content: none;
  margin: 0;
}

.footer_links a {
  color: #0d8426;
}
