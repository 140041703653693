.book-install_link {
  margin: 1.5rem 0;
}

.book-install_link a {
  width: calc(100% - 3.5rem);
}

@media screen and (min-width: 768px) {
  .book-install_link a {
    width: auto;
  }
}
