.image-upload_file-input-label {
  font-weight: 700;
  font-family: 'Montserrat',sans-serif;
  color: #0D8426;
  font-size: 1.4rem;
  width: calc(100% - 3rem);
  display: inline-flex;
  justify-content: center;
  border-radius: 0.4rem;
  border: 0.1rem solid #0D8426;
  padding: 1.4rem 1.4rem;
  cursor: pointer;
}

.image-upload_file-input-label:hover {
  background: #F3FAF4;
  border-color: #006945;
  color: #006945;
}

.image-upload_file-input-label:focus {
  outline: none;
  -webkit-transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease;
  box-shadow: 0 0 0 0.2rem #ffffff, 0 0 0 0.4rem #2a71be;
}

.image-upload_file-list {
  list-style: none;
  padding: 0;
  font-size: 1.4rem;
  margin-bottom: 1.6rem;
}

.image-upload_file-list li  {
  margin-bottom: 1.2rem;
}

.image-upload_file-list div,
.image-upload_file-list img  {
  margin-bottom: 0.8rem;
}

.image-upload_file-list img {
  display: block;
  width: 100%;
}

.image-upload_file-too-big {
  color: #c7151e;
}

.image-upload_file-too-big img {
  border: 1px solid #c7151e;
}

.image-upload_file-label {
  font-weight: 700;
}

.image-upload_file-input-label input {
  display: none;
}

@media screen and (min-width: 480px) {
  .image-upload_file-input-label {
    width: auto;
    font-size: 1.6rem;
  }

  .image-upload_file-list img {
    width: auto;
    max-height: 30rem;
  }
}
