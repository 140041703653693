.back-button {
  margin-bottom: 0.75rem;
}

.back-button_link {
  font-size: 1.4rem;
  cursor: pointer;
}

.back-button_link::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '<';
}
