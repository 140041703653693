.welcome {
  background-image: url(./small_bg.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 100%;
}

.welcome_back {
  padding: 1rem 2rem;
}

.welcome_back a {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  text-decoration: none;
}

.welcome_back a::before {
  content: '<';
  margin-right: 0.5rem;
  color: #158425;
  background-color: white;
  border-radius: 50%;
  padding: 0.3em;
  width: 1rem;
  height: 1rem;
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.welcome_content {
  background-color: white;
  margin: 1rem 2rem;
  padding: 1.5rem;
  border: 1px solid #f9f9f9;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px #00000040;
}

.welcome_content p {
  margin-bottom: 2rem;
}

@media screen and (min-width: 768px) {
  .welcome {
    background-image: url(./large_bg.svg);
  }

  .welcome_content {
    margin: 1rem auto;
    padding: 3rem;
    max-width: 612px;
  }

  .welcome_back {
    padding: 2rem 1rem;
    max-width: calc(612px + 6rem);
    margin: 0 auto;
  }
}
