.header {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  padding: 2rem 2rem 1rem;
}

.header_logo svg {
  height: auto;
  width: 6em;
}

.header_divider {
  background-color: #2f3749;
  color: transparent;
  display: block;
  width: 1px;
  padding: 0;
  margin: 0.25rem 1rem;
}

.header_title {
  font-weight: 700;
  font-size: inherit;
  color: #2f3749;
}
