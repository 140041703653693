.signal-helper-link {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.signal-helper-link a {
  cursor: pointer;
}

div.signal-helper-link_modal {
  width: auto;
  min-height: auto;
  max-width: 80%;
  margin-bottom: 4.8rem;
  padding-bottom: 4.8rem;
}

ul.signal-helper-link_list {
  margin-left: 0;
}

@media screen and (min-width: 768px) {
  div.signal-helper-link_modal {
    max-width: 50rem;
  }
}
