.get-in-touch-link {
  margin: 1.5rem 0;
}

.get-in-touch-link a {
  width: calc(100% - 3.5rem);
}

@media screen and (min-width: 768px) {
  .get-in-touch-link a {
    width: auto;
  }
}
