.form-section {
  height: 100%;
}

.form-section_content {
  background-color: white;
  border: 1px solid #f9f9f9;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px #00000040;
  margin: 1rem;
  padding: 1.5rem;
}

.form-section_content input {
  width: calc(100% - 2rem);
}

@media screen and (min-width: 768px) {
  .form-section {
    background-image: url(./form-section-bg-large.svg);
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .form-section_content {
    margin: 1rem auto;
    padding: 3rem;
    max-width: 612px;
  }

  .form-section_content input {
    width: 100%;
  }
}
