.wrapper {
  color: #555c6b;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper main {
  flex: auto;
}

@media screen and (min-width: 768px) {
  .wrapper {
    height: 100%;
  }
}
