.enter-msns_meter_details:first-of-type {
  margin-bottom: 2rem;
}

.enter-msns_meter_type {
  color: #555C6B;
  font-size: 1.4rem;
  font-weight: 700;
}

.enter-msns_add_elec_msn,
.enter-msns_add_gas_msn {
  margin-top: 1rem;
}

.enter-msns_add_elec_msn span,
.enter-msns_add_gas_msn span  {
  font-size: 1.3rem;
}

.enter-msns_no_gas {
  color: #555C6B;
}

.enter-msns_no_gas label span{
  padding-left: 0.5rem;
}
