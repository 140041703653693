.how-find-this-link {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.how-find-this-link a {
  cursor: pointer;
}

div.how-find-this-link_modal {
  width: auto;
  min-height: auto;
  max-width: 80%;
  margin-bottom: 4.8rem;
  padding-bottom: 4.8rem;
}

@media screen and (min-width: 768px) {
  div.how-find-this-link_modal {
    max-width: 50rem;
  }
}
