.confirm-msns_buttons {
  margin-top: 3rem;
}

.confirm-msns_button {
  margin-bottom: 1rem;
}

.confirm-msns_meter_type {
  color: #555C6B;
  font-size: 1.4rem;
  font-weight: 700;
}

.confirm-msns_meter_msn {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  margin-top: 0.6rem;
  color: #2F3749;
}
