html {
  font-size: 62.5%;
}

html,
body,
#root {
  margin: 0;
  height: 100%;
  min-height: 100%;
}

/* Onetrust modal styling */
#onetrust-pc-sdk, #onetrust-banner-sdk {
  font-family: 'Montserrat', sans-serif;
}

#onetrust-policy-text, #ot-pc-desc, .ot-category-desc {
  font-family: 'Open Sans',sans-serif !important;
}

