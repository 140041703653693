.QSI__EmbeddedFeedbackContainer {
  font-size: 1.6rem !important;
  padding-top: 2rem;
}

div.QSI__EmbeddedFeedbackContainer > fieldset {
  display: block;
}

.QSI__EmbeddedFeedbackContainer_QuestionText {
  font-weight: 700 !important;
  font-size: 1.8rem !important;
  line-height: 1.5 !important;
  font-family: Montserrat, sans-serif;
}

.QSI__EmbeddedFeedbackContainer_TextButton {
  font-family: Montserrat, sans-serif;
  font-weight: 700 !important;
  line-height: 2.1 !important;
  font-size: 1.6rem !important;
  border-width: 0.1rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  width: 100% !important;
  display: inline-flex !important;
  justify-content: center !important;
}

.QSI__EmbeddedFeedbackContainer_Stars {
  font-size: 2rem;
  padding-top: 10px;
}

.QSI__EmbeddedFeedbackContainer_OpenText {
  width: 100% !important;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .feedback-QSI__EmbeddedFeedbackContainer {
    margin: 2rem auto;
    padding: 3rem;
    max-width: 612px;
  }

  .QSI__EmbeddedFeedbackContainer_QuestionText {
    font-weight: 700 !important;
    font-size: 2rem !important;
    line-height: 1.5 !important;
  }

  .QSI__EmbeddedFeedbackContainer_TextButton {
    width: 33% !important;
  }
}


